<template>
  <CCard no-header>
    <CCardBody>
      <h3><CIcon name="cil-pencil" /> {{ operationName }} Add On</h3>
      <CAlert v-if="message" :color="alertType"> {{ message }} </CAlert>
      <CRow>
        <CCol sm="3">
          <label style="width: 95%"
            >Name
            <a @click="toggleNameTranslations = !toggleNameTranslations"
              ><CIcon
                name="cil-globe-alt"
                style="margin-bottom: 5px"
                class="text-primary"
              ></CIcon
            ></a>
          </label>

          <div class="form-group">
            <div
              class="input-group"
              v-if="addOn.nameTranslations[0]"
              v-show="!toggleNameTranslations"
            >
              <div class="input-group-prepend">
                <span
                  v-text="addOn.nameTranslations[0].languageCode"
                  class="input-group-text"
                  v-show="!toggleNameTranslations"
                />
              </div>
              <input
                id="name"
                name="name"
                type="text"
                class="form-control"
                v-model="addOn.nameTranslations[0].name"
              />
            </div>
          </div>
          <div
            class="form-group"
            v-show="toggleNameTranslations"
            v-for="(language, k) in addOn.nameTranslations"
            :key="k"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <span v-text="language.languageCode" class="input-group-text" />
              </div>
              <input
                @change="saveNameTranslation(language.languageCode, $event)"
                type="text"
                class="form-control"
                @id="language.languageCode"
                v-model="addOn.nameTranslations[k].name"
              />
            </div>
          </div>
        </CCol>

        <CCol>
          <CInput
            label="Min Quantity"
            type="text"
            v-model="addOn.minQuantity"
          ></CInput
        ></CCol>
        <CCol>
          <CInput
            label="Max Quantity"
            type="text"
            v-model="addOn.maxQuantity"
          ></CInput
        ></CCol>
        <CCol>
          <CInput label="Price" type="text" v-model="addOn.price"></CInput
        ></CCol>
        <CCol>
          <CInput
            label="Currency code"
            type="text"
            v-model="addOn.currencyCode"
          ></CInput
        ></CCol>
      </CRow>

      <CRow>
        <CCol col="9"></CCol>
        <CCol class="d-flex justify-content-end">
          <CButton color="secondary" class="btn-lg" @click="goBack"
            ><CIcon name="cil-list" /> Back to the list</CButton
          >

          <CButton
            color="primary"
            @click="save()"
            class="btn-lg"
            style="margin-left: 15px"
            ><CIcon name="cil-save" /> Save</CButton
          >
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
export default {
  name: "AddNewAddOn",
  data: () => {
    return {
      operationName: "Create New",
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      token: localStorage.getItem("api_token"),

      toggleNameTranslations: false,

      // Alert
      alertType: "danger",
      message: null,

      addOn: {
        id: "00000000-0000-0000-0000-000000000000",
        nameTranslations: [],
        minQuantity: null,
        maxQuantity: null,
        pice: null,
        currencyCode: null,
      },
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    get(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/AddOn/${id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then(function (response) {
          self.addOn = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    save() {
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/AddOn`, self.addOn, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          self.addOn.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully created add on.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    saveNameTranslation: function (value, event) {
      let self = this;
      self.addOn.nameTranslations[value] = event.target.value;
    },
    getLanguages() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ListItem/Languages`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then(function (response) {
          self.languages = response.data;
          if (self.addOn.id == self.emptyGuid) {
            for (let i = 0; i < self.languages.length; i++) {
              self.addOn.nameTranslations.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].id,
              });
            }
          }
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    let self = this;
    self.getLanguages();
    if (this.$route.params.id != this.emptyGuid) {
      this.operationName = "Edit";
      this.get(this.$route.params.id);
    }
  },
};
</script>